import {EntityService} from '../../../service/EntityService';

export class TaxAuthoritiesService extends EntityService {

  static entity = 'iam-system/tax-authorities';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }


  static create(data: any): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }

  static update(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}`, data).then(res => res.data);
  }

  static delete(id: string, params?: any): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}`, {params}).then(res => res.data);
  }

}