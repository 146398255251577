import * as languages from './languages.json';

const locale = 'vi';

export function translate(text): string {
  if (text) {
    if (text.includes && text.includes('+')) {
      let result = '';
      text.split('+').forEach((text) => {
        result += ` ${translate(text)}`;
      });
      return result.trim();
    } else {
      if (languages[text] && languages[text][locale] !== undefined) {
        return languages[text][locale];
      }
    }
  }
  return text;
}
