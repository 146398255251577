import moment from 'moment';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {Checkbox} from 'primereact/checkbox';
import {classNames} from 'primereact/utils';

import {translate} from '../language';

type EditMode = '' | 'cell';

interface DataTableBasicProps {
  header?: string;
  dataKey?: string;
  columns: Array<IamColumn>;
  items: Array<any>;
  editMode?: EditMode;

  indexColumnWidth?: number;
}

export const useDataTableBasic = (props: DataTableBasicProps) => {

  const {header, dataKey, columns, items, editMode, indexColumnWidth} = props;

  const render = () => {
    let tableHeader;
    if (header) {
      tableHeader = (
        <div className="table-header">
          {translate(header)}
        </div>
      );
    }
    let className;
    switch (editMode) {
      case 'cell':
        className = 'editable-cells-table p-fluid';
        break;
    }

    let indexColumn;
    if (indexColumnWidth) {
      const indexColumnTemplate = (data, props) => {
        return props.rowIndex + 1;
      }
      indexColumn = <Column header="#" body={indexColumnTemplate} style={{minWidth: indexColumnWidth, maxWidth: indexColumnWidth}} className="justify-content-center"/>;
    }

    const {dynamicColumns} = makeDynamicColumns(columns);

    return (
      <DataTable header={tableHeader} value={items} dataKey={dataKey} editMode={editMode}
                 showGridlines stripedRows size="small" resizableColumns columnResizeMode="fit" responsiveLayout="scroll"
                 scrollable scrollHeight="flex" className={className}>
        {indexColumn}
        {dynamicColumns}
      </DataTable>
    );
  }

  return {render};
}

type MatchMode = 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'custom';
type DataType = ('' | 'date' | 'date-time' | 'number' | 'boolean' | 'checkbox' | 'fromFilterOptions' | 'custom');
type FilterType = ('' | 'dropdown');

export interface IamColumn {
  field: string;
  header: string;
  matchMode?: MatchMode;
  filterType?: FilterType;
  filterOptions?: Array<any>;
  dataType?: DataType;

  customCell?(rowData: any): any;

  style?: any;

  sortable?: boolean;

  editable?: boolean;

  onCellEditComplete?(e: any): void;
}

export const makeDynamicColumns = (columns: Array<IamColumn>) => {
  let filterDisplay;
  const dynamicColumns = columns.map((col) => {
    let filterElement = null;
    switch (col.filterType) {
      case 'dropdown':
        filterElement = (options) => {
          return <Dropdown value={options.value} options={col.filterOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="-" className="p-column-filter" showClear/>;
        }
        break;
    }

    let body = null;
    switch (col.dataType) {
      case 'date':
        body = (rowData) => {
          if (rowData[col.field]) {
            return moment(rowData[col.field]).format('DD/MM/YYYY');
          } else {
            return '';
          }
        }
        break;
      case 'date-time':
        body = (rowData) => {
          if (rowData[col.field]) {
            return moment(rowData[col.field]).format('DD/MM/YYYY hh:mm:ss');
          } else {
            return '';
          }
        }
        break;
      case 'number':
        body = (rowData) => {
          if (typeof rowData[col.field] === 'number') {
            return rowData[col.field].toLocaleString();
          } else {
            return '';
          }
        }
        col.style = col.style ? Object.assign({justifyContent: 'end'}, col.style) : {justifyContent: 'end'};
        break;
      case 'boolean':
        body = (rowData) => {
          return <i className={classNames('pi', {'true-icon pi-check-circle': rowData[col.field], 'false-icon pi-times-circle': !rowData[col.field]})}/>
        }
        col.style = col.style ? Object.assign(col.style, {justifyContent: 'center'}) : {justifyContent: 'center'};
        break;
      case 'checkbox':
        body = (rowData) => {
          return <Checkbox checked={rowData[col.field]} readOnly/>
        }
        col.style = col.style ? Object.assign(col.style, {justifyContent: 'center'}) : {justifyContent: 'center'};
        break;
      case 'fromFilterOptions':
        body = (rowData) => {
          if (rowData[col.field]) {
            if (col.filterOptions) {
              let value = '';
              col.filterOptions.forEach((option: any) => {
                if (rowData[col.field] === option.value) {
                  value = option.label;
                }
              });
              return value;
            }
            return '';
          } else {
            return '';
          }
        }
        break;
      case 'custom':
        body = (rowData) => {
          return col.customCell(rowData);
        }
        break;
    }
    if (col.matchMode) {
      filterDisplay = 'row';
    }

    let editor;
    if (col.editable) {
      editor = (options) => {
        switch (col.dataType) {
          case 'number':
            return <InputNumber value={options.value} onValueChange={e => options.editorCallback(e.value)}/>
          case 'checkbox':
            return <Checkbox checked={options.value} onChange={e => options.editorCallback(e.checked)}/>
        }
      }
    }

    return <Column key={col.field} field={col.field} header={translate(col.header)} style={col.style} body={body}
                   editor={editor} onCellEditComplete={col.onCellEditComplete}/>;
  });
  return {filterDisplay, dynamicColumns};
}
