import axios from 'axios';

import {baseURL} from '../config';

import {AppStorage} from './UserStorage';
import {ToastService} from '@iamsoftware/react-hooks';
import {LoginService} from '../pages/login/LoginService';

const _axios = axios.create({
  baseURL
});

_axios.interceptors.request.use(function (config) {
  startLoading();
  try {
    // config.headers.apikey = AppStorage.get('token')
    config.headers['X-CSRF-Token'] = AppStorage.get('moquiSessionToken');
  } catch (e) {
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
_axios.interceptors.response.use((response) => {
  stopLoading();
  return response;
}, (error) => {
  stopLoading();
  if (error && error.response && error.response.status === 401) {
    // LoginService.getToken().then(() => {
      window.location.hash = '/login';
    // });
  } else if (error.response && error.response.data && error.response.data.errors) {
    ToastService.error(error.response.data.errors);
  }
  return Promise.reject(error);
});

const startLoading = () => {
  const body = document.getElementsByTagName('BODY')[0];
  body.classList.add('loading');
}

const stopLoading = () => {
  const body = document.getElementsByTagName('BODY')[0];
  body.classList.remove('loading');
}

export default _axios;
