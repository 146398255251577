import {Route, Routes} from 'react-router-dom';


export default function DanhMuc() {

  return (
    <Routes>
    </Routes>
  );
}
