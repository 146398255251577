import {ConfirmDialogService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';
import {AgentAccountService as Service} from './AgentAccountService';
import {ToastService} from '../../../AppToast';
import {SelectButton} from 'primereact/selectbutton';
import {useEffect, useState} from 'react';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

export const AgentAccounts = () =>{
  const header = 'Tài khoản đại lý';
  const dataKey = "userId";

  const [requiredParams, setRequiredParams] = useState(null);
  const [disabled, setDisabled] = useState('N');


  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader:header,
    dataKey,
    columns:[
      {field:'username', header:'Tên đăng nhập', minWidth: 150, matchMode:'contains'},
      {field:'pseudoId', header:'Mã đại lý', minWidth: 150, matchMode:'contains'},
      {field:'partyName', header:'Tên đại lý', minWidth: 150, matchMode:'contains'},
      {field:'emailAddress', header:'Email', minWidth: 150, matchMode:'contains'},
      {field:'partyTaxId', header:'Mã số thuế', minWidth: 150, matchMode:'contains'},
    ],
    indexColumnWidth:45,
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-refresh', title: 'Reset mật khẩu', className: 'p-button-secondary', command: () => update(item)},
          ]
          if (item.disabled === 'N'){
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doLock(item)},)
          }else if (item.disabled === 'Y'){
            item.actions.push({icon: 'pi pi-unlock', title: 'Mở khóa', className: 'p-button-success', command: () => doUnlock(item)},)
          }
        })
      }
    },
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent,{disabled: disabled})
    }
  })

  useEffect(() => {
    reloadLazyData()
  }, [disabled]);

  const {render: renderDialogCrup, update} = useDialogCrup({
    header:'Cập nhật mật khẩu',
    dataKey,
    width: '40rem',
    fields:[
      {field:'password', header:'Mật khẩu mới' ,required: true, className:'md:col-12'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.reset(id,item).then()
    },
    reloadLazyData,
  })

  const doLock = (item) =>{
    if (item[dataKey]){
      ConfirmDialogService.confirm('Xác nhận khóa tài khoản','Bạn có muốn khóa tài khoản này không?',()=>{
        Service.lock(item[dataKey]).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doUnlock = (item) =>{
    if (item[dataKey]){
      ConfirmDialogService.confirm('Xác nhận mở khóa tài khoản','Bạn có muốn mở khóa tài khoản này không?',()=>{
        Service.unlock(item[dataKey]).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const clearAndSwitch = (data) => {
    setDisabled(data);
  };

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={disabled} options={statuses} onChange={e => clearAndSwitch(e.value)}/>
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}