import {Service} from './Service';

export class CommonService extends Service {

  static entity: string = 'iam-common';

  static getProvinces(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/provinces`).then(res => res.data);
  }

  static getCounties(stateProvinceGeoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${stateProvinceGeoId}/assocs?toGeoTypeEnumId=GEOT_COUNTY`).then(res => res.data);
  }

  static getPartyType(): Promise<any> {
    return this.axios.get(`${this.entity}/party-type`).then(res => res.data);
  }

  static getProductCategory(): Promise<any> {
    return this.axios.get(`${this.entity}/request-product-category`).then(res => res.data);
  }
  static getProductClassType(): Promise<any> {
    return this.axios.get(`${this.entity}/product-class-types`).then(res => res.data);
  }

  static getTaxAuthority(geoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${geoId}/tax-authority`).then(res => res.data);
  }

  static getPartyByTax(partyTaxId: string): Promise<any> {
    return this.axios.get(`iam-party/info/by-tax-code?partyTaxId=${partyTaxId}`).then(res => res.data);
  }
}
