import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, FormatDisplay, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';
import {ProductService} from '../../danh-muc/goi-dich-vu/ProductService';

export const useCrupRequestItems = ({display, readOnly, productCategoryId}) => {
  const dataKey = 'dataKey';

  const [items, setItems] = useState([]);

  const [products, setProducts] = useState([]);
  const [itemProductDelIds, setItemProductDelIds] = useState([])
  const [categoryName, setCategoryName] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [headerResult, setHeaderResult] = useState(null);
  const [actionColumnWith, setActionColumnWith] = useState(0);
  const [total, setTotal] = useState('');
  useEffect(() => {
    if (display) {
      setItemProductDelIds([])
      setSelectedProduct([])
      setSelectedResult(null)
      setSelectedItems(null);
    }
    if (readOnly === false) {
      setActionColumnWith(50)
    } else setActionColumnWith(0)

    setHeaderResult(tabHeader)
  }, [display, readOnly]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (display){
      ProductService.getProductList('', 'N', productCategoryId).then(data => {
        setProducts(data.listData);
      });
    }
  }, [display, productCategoryId])


  useEffect(() => {
    if (display && items?.length) {
      items.forEach((item, index) => {
        if (!item[dataKey]) {
          item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
        }
        if (!item.sourcePercentage) {
          item.sourcePercentage = 0
        }
      });
    }
  }, [display, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps
  const refreshData = (data) => {
    if (data?.length > 0) {
      setTotal(`${data.length} Yêu cầu`)
      data.forEach(item => {
        item.actions = []
        if (readOnly === false) {
          item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doRowDelete(item)})
        }
      })
    }
  }
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTableBasic, selectedItems: selectedProduct ,setSelectedItems: setSelectedProduct} = useDataTableBasic({
    tableHeader:`${products?.length} gói dịch vụ`,
    dataKey:'productId',
    columns:[
      {field:'pseudoId', header:'Mã sản phẩm', width:175, matchMode:'contains'},
      {field:'productName', header:'Tên sản phẩm', minWidth:250, matchMode:'contains'},
      {field:'price', header:'Đơn giá', width:150, matchMode:'contains',dataType:'number'},
      {field:'amountValue', header:'Số lượng', width:150, matchMode:'contains'},
    ],
    indexColumnWidth:45,
    selectionMode:'radiobutton',
    items: products
  })

  useEffect(() => {
    if (selectedProduct){
      setSelectedResult([selectedProduct])
    }
  }, [selectedProduct]);

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header: '!Thêm gói dịch vụ',
    dataKey,
    width: '80rem',
    fields: [
      {type:'custom', body: <div>{renderDataTableBasic()}</div>}
    ],
    createItem: item => {
      setItems(prevItems => {
        if (selectedResult[0]?.productId){
          prevItems.push(selectedResult[0])
        }
        return prevItems;
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });
  const product = form.getRawValue();
  // useEffect(() => {
  //   const _value = form.getValue();
  //   if (_value?.product) {
  //     ProductService.get(_value.product).then(data => {
  //       _value.unitAmount = data.price;
  //       form.setValue(_value);
  //     })
  //   }
  // }, [product.product]);

  // useEffect(() => {
  //   const _value = form.getRawValue();
  //   _value.unitAmount = product?.unitAmount;
  //   _value.quantityUom = product?.quantityUom;
  //   _value.quantityUomId = product?.quantityUomId;
  //   _value.price = _value.unitAmount > 0 && _value.quantity > 0 ? _value.unitAmount * _value.quantity : 0;
  //   form.setValue(_value);
  // }, [product?.quantity, product?.unitAmount]);


  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: headerResult,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field:'pseudoId', header:'Mã sản phẩm', width:175, matchMode:'contains'},
      {field:'productName', header:'Tên sản phẩm', minWidth:250, matchMode:'contains'},
      {field:'price', header:'Đơn giá', width:200, matchMode:'contains',dataType:'number'},
      {field:'amountValue', header:'Số lượng', width:200, matchMode:'contains'},
    ],
    items,
    actionColumnWidth: actionColumnWith
  });

  const doCreate = () => {
    setSelectedProduct([])
    create();
  }
  const doRowUpdate = (data) => {
    form.reset()
    console.log(data)
    update(data)
  }
  const doRowDelete = (data) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa sản phẩm ${data.productName} ra khỏi danh sách?`, () => {
      setItems(prevItems => {
        if (data.orderItemSeqId) {
          itemProductDelIds.push(data.orderItemSeqId)
        }
        return prevItems.filter(prevItem => prevItem[dataKey] !== data[dataKey]);
      });
    });
  }

  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{minHeight: '15.5rem', maxHeight: 'calc(100vh - 35.4rem)'}}>
          {render()}
        </div>
        {renderDialogCrup()}
      </div>
    );
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Danh sách sản phẩm</span>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" disabled={readOnly} onClick={doCreate}/>
        </Fragment>
      </div>
    </div>
  );
  return {renderProducts, items, setItems, itemProductDelIds};
}