import {Fragment, useEffect, useState} from 'react';

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {SentMailHistoryService as Service} from './SentMailHistoryService';
import {Button} from 'primereact/button';
import {MailInformationHTML} from './MailInformationHTML';

export const HistorySentMail = ()=>{
  const dataKey = 'emailMessageId';
  const header = 'Lịch sử gửi mail';

  const [display, setDisplay] = useState(null)

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: header,
    dataKey,
    columns:[
      {field:'emailTypeEnum', header:'Loại email', matchMode:'contains', minWidth:150},
      {field:'toPseudoId', header:'Mã đại lý/KH', matchMode:'contains', minWidth:150},
      {field:'toPartyName', header:'Tên đại lý/KH', matchMode:'contains', minWidth:150},
      {field:'toAddresses', header:'Nơi nhận', matchMode:'contains', minWidth:150},
      {field:'subject', header:'Tiêu đề', matchMode:'contains', minWidth:150},
      {field:'status', header:'Trạng thái', matchMode:'contains', minWidth:150},
      {field:'sentDate', header:'Ngày gửi', minWidth:150, dataType:'date-time'},
      {field:'fromAddress', header:'Nơi gửi', matchMode:'contains', minWidth:150},
      {field:'fromName', header:'Người gửi', matchMode:'contains', minWidth:150},
    ],
    indexColumnWidth:45,
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-send', title: 'Gửi lại email', className: 'p-button-secondary', command: () => update(item)},
            {icon: 'pi pi-eye', title: 'Nội dung', className: 'p-button-info', command: () => doReview(item)},
          ]
        })
      }
    },
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent).then()
    }
  })

  const {render: renderDialogCrup, update} = useDialogCrup({
    header:'!Gửi lại email',
    dataKey,
    fields:[
        {field:'emailAddress', header:'Địa chỉ email',required:true, className:'md:col-12'},
      {type:'custom', body:<i>(Mỗi email cách nhau bởi dấu ,)</i>}
    ],
    width:'40rem',
    updateItem(id: string, item: any): Promise<any> {
      return Service.resend(id, item).then()
    }
  })

  const doReview = (item) =>{
    if (item[dataKey]){
      Service.review(item[dataKey]).then(data =>{
        setDisplay(data);
      })
    }
  }

  // const doResend = (item) => {
  //   if (item[dataKey]){
  //     ConfirmDialogService.confirm('Xác nhận gửi lại email','Bạn có muốn gửi lại email này?',() =>{
  //       Service.resend(item[dataKey]).then(() =>{
  //         ToastService.success();
  //         reloadLazyData()
  //       })
  //     })
  //   }
  // }

  return (
    <div className="grid">
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      <MailInformationHTML display={display} setDisplay={setDisplay}/>
    </div>
  )
}