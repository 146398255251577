import {Fragment, useEffect, useState} from 'react';

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {TaxAuthoritiesService as Service} from './TaxAuthoritiesService';
import {CommonService} from '../../../service/CommonService';
import {Button} from 'primereact/button';

export const TaxAuthorities = () =>{
  const dataKey = 'taxAuthorityId';
  const header = 'Cơ quan thuế';

  const [geoIds, setGeoIds] = useState([])

  useEffect(() => {
    CommonService.getProvinces().then(({listData}) =>{
      setGeoIds(listData.map(item =>{
        return {value: item.toGeoId, label: `${item.geoName}`}
      }))
    })
  }, []);

  const tableHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Danh sách cơ quan thuế</span>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => doCreate()}/>
        </Fragment>
      </div>
    </div>
  )

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: tableHeader,
    dataKey,
    columns: [
      {
        field: 'taxAuthorityCode', header: 'Mã CQT', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <span className="underline text-primary" onDoubleClick={() => doUpdate(rowData, true)} title="Double Click để xem chi tiết">{rowData.taxAuthorityCode}</span>
        }
      },
      {field: 'description', header: 'Tên CQT', minWidth: 250, matchMode: 'contains'},
      {field: 'geoName', header: 'Địa chỉ', minWidth: 150, matchMode:'contains'},
    ],
    indexColumnWidth:45,
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions =[
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item, false)},
            {icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)},
          ]
        })
      }
    },
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent).then()
    }
  })

  const {render: renderDialogCrup ,view, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields:[
      {field:'taxAuthorityCode' , header:'Mã CQT', required: true, className:'md: col-12'},
      {field:'description' , header:'Tên CQT', required: true, className:'md: col-12'},
      {field:'taxAuthGeoId' , header:'Địa chỉ', className:'md: col-12', type:'Dropdown', DropdownProps:{options: geoIds, filter: true}},
    ],
    createItem(item: any): Promise<any> {
      return Service.create(item)
    },
    updateItem(id: string, item: any): Promise<any> {
      return Service.update(id,item)
    },
    reloadLazyData
  })

  const doCreate = () =>{
    create()
  }

  const doUpdate = (item: any, doView: boolean) =>{
    if (item){
      if (doView === true){
        view(item)
      }else {
        update(item)
      }
    }
  }

  const doDelete = (item) =>{
    if (item.taxAuthorityId){
      ConfirmDialogService.confirm('Xóa cơ quan thuế','Ban có xác nhận xóa cơ quan thế này?',()=>{
        Service.delete(item.taxAuthorityId).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  return (
    <div className="grid">
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}