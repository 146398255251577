import {useEffect, useRef} from 'react';

import {Toast, ToastMessage} from 'primereact/toast';

import {BehaviorSubject} from 'rxjs';

const Severity = {
  success: 'success',
  info: 'info',
  warn: 'warn',
  error: 'error'
}
const message = new BehaviorSubject(null);
export const ToastService = {
  Severity: Severity,
  setMessage: (data: ToastMessage) => message.next(data),
  getMessage: () => message.asObservable(),
  success: (detail?: string) => {
    message.next({severity: Severity.success, summary: 'Thành công', detail: detail ? detail : 'Dữ liệu đã được cập nhật!'})
  },
  error: (detail?: string) => {
    message.next({severity: Severity.error, summary: 'Có lỗi xảy ra', detail: detail ? detail : 'Lỗi không xác định...'})
  }
};

export const AppToast = () => {
  const toast = useRef(null);

  useEffect(() => {
    ToastService.getMessage().subscribe((message) => {
      if (message) {
        toast.current.show(message);
      }
    });
  }, []);

  return <Toast ref={toast}/>
}
