import {Route, Routes} from 'react-router-dom';

import {Requests} from './yeu-cau';
import {Customers} from './danh-sach';

export const Customer = () => {

  return (
    <Routes>
      <Route path="yeu-cau" element={<Requests/>}/>
      <Route path="danh-sach" element={<Customers/>}/>
    </Routes>
  );
}