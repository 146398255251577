import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Toast} from 'primereact/toast';
import {useForm} from '@iamsoftware/react-hooks';
import {DashboardService as Service} from './DashboardService';
import {AppStorage} from '../../service/UserStorage';

export const ChangePassword = ({display, setDisplay}) => {
  const toast = useRef(null);

  const [headerDate, setHeaderDate] = useState('')
  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Đổi mật khẩu<br/> {headerDate}</span>
    </div>
  )

  const form = useForm({
    fields: [
      {field: 'oldPassword', header: 'Mật khẩu cũ', required: true, className: 'md:col-12', type: 'Password'},
      {field: 'newPassword', header: 'Mật khẩu mới', required: true, className: 'md:col-12', type: 'Password'},
      {field: 'newPasswordVerify', header: 'Xác nhận mật khẩu mới', required: true, className: 'md:col-12', type: 'Password'},
    ],
  });
  const showError = () => {
    toast.current.show({severity: 'error', summary: 'Có lỗi xảy ra', detail: 'Mật khẩu mới và Xác nhận mật khẩu mới không khớp', life: 3000});
  }
  const showSuccess = (message) => {
    toast.current.show({severity: 'success', summary: 'Thành công', detail: message, life: 3000});
  }

  const onSubmit = () => {
    if (form.valid()) {
      const _value = form.getValue();
      if (_value.confirmPassword !== _value.confirmNewPassword) {
        showError()
      } else {
        delete _value.confirmNewPassword
        Service.changePassword(_value).then(data => {
          if (data.success === true) {
            showSuccess(data?.message)
            setDisplay(null)
          }
        })
      }
    }
  }
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button className="p-button-outlined p-button-sm p-button-secondary" icon="pi pi-times" label="Đóng" onClick={() => setDisplay(null)}/>
      <Button className="p-button-outlined p-button-sm p-button-warning" icon="pi pi-pencil" label="Cập nhật" onClick={() => onSubmit()}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: "400px"}} onHide={() => setDisplay(null)}>
      <Toast ref={toast}/>
      {form.render()}
    </Dialog>
  );

}
