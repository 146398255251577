import {useEffect, useRef, useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import classNames from 'classnames';

import {Menubar} from 'primereact/menubar';
import {Menu} from 'primereact/menu';

import {BreadcrumbsService} from './AppBreadCrumb';

import {AppStorage} from '../service/UserStorage';

import {AppConfig} from './AppConfig';
import {useDialogCrup} from '@iamsoftware/react-hooks';
import {LoginService} from '../pages/login/LoginService';
import {ChangePassword} from './dashboard/ChangePassword';

export const AppTopbar = (props) => {

  const menu = useRef(null);
  const location = useLocation();
  const [appMenus, setAppMenus] = useState([])
  const [displayChangePassword, setDisplayChangePassword] = useState(null)


  useEffect(() => {
    setAppMenus(menu1)
  }, []);

  const template = (item, options) => {
    if (item.target) {
      return (
        <NavLink to={item.target} className={options.className}>
          <span className={options.iconClassName}/>
          <span className={options.labelClassName}>{item.label}</span>
        </NavLink>
      );
    } else {
      return (
        <a href="/" className={options.className} target={item.target} onClick={options.onClick}>
          <span className={options.iconClassName}/>
          <span className={options.labelClassName}>{item.label}</span>
        </a>
      );
    }
  };

  const menu1: Array<any> = [
    {
      label: 'Tổng quan', icon: 'pi pi-fw pi-home', target: '/', template
    },
    {
      label: 'Đại lý', icon: 'pi pi-fw pi-sitemap', className: 'app-menu-20', items: [
        {label: 'Tạo mới đại lý', icon: 'pi pi-fw pi-plus-circle', target: 'dai-ly/danh-sach/them-moi', template},
        {label: 'Danh sách đại lý', icon: 'pi pi-fw pi-circle', target: 'dai-ly/danh-sach', template}
      ]
    },
    {
      label: 'Khách hàng', icon: 'pi pi-fw pi-users', className: 'app-menu-22', items: [
        {label: 'Yêu cầu', icon: 'pi pi-fw pi-circle', target: '/khach-hang/yeu-cau', template},
        {label: 'Danh sách khách hàng', icon: 'pi pi-fw pi-circle', target: '/khach-hang/danh-sach', template},
        {label: 'Danh sách hợp đồng', icon: 'pi pi-fw pi-circle', target: '/admin/import/multiple-transaction', template},
        {label: 'Thanh toán', icon: 'pi pi-fw pi-circle', target: '/admin/import/multiple-transaction', template}]
    },
    {
      label: 'Danh mục', icon: 'pi pi-fw pi-list', className: 'app-menu-22', items: [
        {label: 'Gói dịch vụ', icon: 'pi pi-fw pi-circle', target: '/danh-muc/goi-dich-vu', template},
        {label: 'Cơ quan thuế', icon: 'pi pi-fw pi-circle', target: '/danh-muc/co-quan-thue', template}
      ]
    },
    {
      label: 'Hệ thống', icon: 'pi pi-fw pi-sliders-h', className: 'app-menu-22', items: [
        {label: 'Lịch sử gửi mail', icon: 'pi pi-fw pi-circle', target: '/he-thong/lich-su-gui-mail', template},
        {label: 'Quản lý tài khoản đại lý', icon: 'pi pi-fw pi-circle', target: '/he-thong/tai-khoan-dai-ly', template},
        {label: 'Quản lý tài khoản khách hàng', icon: 'pi pi-fw pi-circle', target: '/he-thong/tai-khoan-kh', template},
      ]
    },
    {
      label: 'Báo cáo', icon: 'pi pi-fw pi-chart-line', className: 'app-menu-26', items: [

      ]
    }
  ];

  const logout = () => {
    LoginService.logout().then(() => {
      window.location.hash = '/login';
    });
  }
  const accountMenus = [
    {
      label: 'Đổi mật khẩu', icon: 'pi pi-fw pi-key', command: () => {
        setDisplayChangePassword(Date.now())
      }
    },
    {separator: true},
    {
      label: 'Đăng xuất', icon: 'pi pi-fw pi-sign-out', command: () => {
        logout()
      }
    }
  ];

  const setMenuDescriptionRecursive = (menu) => {
    menu.recursiveLabels.push(menu.label);
    if (menu.items) {
      menu.items.forEach((item) => {
        item.recursiveLabels = [...menu.recursiveLabels];
        setMenuDescriptionRecursive(item);
      });
    }
  };

  const getMenuDescriptionRecursive = (pathname, menu) => {
    let recursiveLabels;
    if (pathname === menu.target) {
      recursiveLabels = menu.recursiveLabels;
    } else if (pathname === `${menu.target}/create`) {
      recursiveLabels = menu.recursiveLabels;
      recursiveLabels.push('Tạo mới');
    } else if (pathname === `${menu.target}/detail`) {
      recursiveLabels = menu.recursiveLabels;
      recursiveLabels.push('Chi tiết');
    } else if (pathname === `${menu.target}/update`) {
      recursiveLabels = menu.recursiveLabels;
      recursiveLabels.push('Chỉnh sửa');
    }
    if (!recursiveLabels && menu.items) {
      for (const item of menu.items) {
        recursiveLabels = getMenuDescriptionRecursive(pathname, item);
        if (recursiveLabels) {
          break;
        }
      }
    }
    return recursiveLabels;
  };

  const getMenuDescription = () => {
    let recursiveLabels;
    for (const menu of appMenus) {
      recursiveLabels = getMenuDescriptionRecursive(location.pathname, menu);
      if (recursiveLabels) {
        break;
      }
    }
    if (recursiveLabels) {

      const breadcrumbs = [];
      recursiveLabels.forEach((label) => {
        breadcrumbs.push({label});
      });
      BreadcrumbsService.setBreadcrumbs(breadcrumbs);
    }
  };

  useEffect(() => {
    appMenus.forEach((menu) => {
      menu.recursiveLabels = [];
      setMenuDescriptionRecursive(menu);
    });
    getMenuDescription();
  }, [location]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="layout-topbar">
      <div className="layout-topbar-logo">
        <img src={'assets/homecasta/logo.png'} alt="logo"/>
      </div>

      <Menubar model={appMenus} className="app-menu" style={{marginLeft: '70px'}}/>

      <ul className={classNames("layout-topbar-menu lg:flex origin-top")}>
        <li>
          <AppConfig {...props}/>
        </li>
        <li>
          <button className="p-link layout-topbar-button" onClick={(event) => menu.current.toggle(event)}>
            <i className="pi pi-user"/>
            <span>Profile</span>
          </button>
          <span/>
          <Menu model={accountMenus} popup ref={menu} id="popup_menu"/>
          <ChangePassword display={displayChangePassword} setDisplay={setDisplayChangePassword}/>
        </li>
      </ul>
    </div>
  );
}

