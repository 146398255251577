import {Dialog} from 'primereact/dialog';


export const MailInformationHTML = ({display ,setDisplay}) =>{
  const header = 'Nội dung Email';
  return (
    <Dialog onHide={() => setDisplay(null)} header={header} visible={!!display} style={{width: "100wv", height:"100hv"}} position="top">
      <div dangerouslySetInnerHTML={{__html: display?.body}}></div>
    </Dialog>
  )
}