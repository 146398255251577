import {Route, Routes} from 'react-router-dom';

import DanhMuc from './danh-muc';
import {HistorySentMail} from './lich-su-gui-mail';
import {AgentAccounts} from './tai-khoan-dai-ly';
import {CustomerAccounts} from './tai-khoan-kh';

export default function HeThong() {

  return (
    <Routes>
      <Route path="danh-muc/*" element={<DanhMuc/>}/>
      <Route path="lich-su-gui-mail" element={<HistorySentMail/>}/>
      <Route path="tai-khoan-dai-ly" element={<AgentAccounts/>}/>
      <Route path="tai-khoan-kh" element={<CustomerAccounts/>}/>
    </Routes>
  );
}
