import {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Route, Routes} from 'react-router-dom';

import {AppTopbar} from './AppTopbar';
import {AppBreadCrumb} from './AppBreadCrumb';

import Dashboard from './dashboard';
import HeThong from './he-thong';
import {AppStorage} from '../service/UserStorage';
import {Agents} from './dai-ly';
import {Customer} from './khach-hang';
import {Category} from './danh-muc';

export default function App() {

  const [layoutColorMode, setLayoutColorMode] = useState('light');

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode)
  }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-theme-light': layoutColorMode === 'light'
  });
  useEffect(() => {
  }, []);
  return (
    <div className={wrapperClass}>
      <AppTopbar layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange}/>
      <AppBreadCrumb/>

      <div className="layout-main-container">
        <div className="layout-main">
          <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="dai-ly/*" element={<Agents/>}/>
            <Route path="khach-hang/*" element={<Customer/>}/>
            <Route path="danh-muc/*" element={<Category/>}/>
            <Route path="he-thong/*" element={<HeThong/>}/>
          </Routes>
        </div>
      </div>
    </div>
  );
}
