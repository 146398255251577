import {Service} from './Service';

export class PartyService extends Service {

  static entity: string = 'iam-party';

  static getByTaxCode(partyTaxId: string): Promise<any> {
    return this.axios.get(`${this.entity}/info/by-tax-code?partyTaxId=${partyTaxId}`).then(res => res.data);
  }

  static genders = [
    {value: 'M', label: 'Nam'},
    {value: 'F', label: 'Nữ'}
  ];

  static identifyTypes = [
    {value: 'PtidCccd', label: 'Căn cước công dân'},
    {value: 'PtidCmnd', label: 'Chứng minh nhân dân'}
  ];

}
