import {useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Messages} from 'primereact/messages';

import {translate} from '../..';

import {LoginService} from './LoginService';

import {AppStorage} from '../../service/UserStorage';

import {AppFooter} from '../../AppFooter';
import {ToastService} from '../../AppToast';

export default function Login() {

  const message = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    getToken();
  }, []);

  const getToken = () => {
    LoginService.getToken().then(({moquiSessionToken}) => {
      AppStorage.set('moquiSessionToken', moquiSessionToken);
    });
  }
  //
  // useEffect(() => {
  //   document.documentElement.style.fontSize = '14px';
  //
  //   setSubmitted(true);
  //   LoginService.logout().finally(() => {
  //     setSubmitted(false);
  //
  //     AppStorage.remove('token');
  //   });
  // }, []);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    document.documentElement.style.fontSize = '14px';
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  const handleSubmit = (event) => {
    if (!submitting) {
      setSubmitting(true);
      LoginService.login(username, password).then(data => {
        if (data.isSuccess === 'Y') {
          AppStorage.set('moquiSessionToken', data.moquiSessionToken);
          window.location.hash = '/';
        } else {
          data?.errors.forEach(error => {
            message.current.show({severity: 'error', content: error, sticky: true});
          });
          getToken();
        }
      }).catch(() => getToken()).finally(() => {
        setSubmitting(false);
      })
    }
    event.preventDefault();
  }
  return (

    <div className="layout-wrapper layout-overlay layout-theme-light">
      <div className="layout-topbar">
        <div className="layout-topbar-logo">
          <img src={'assets/homecasta/logo.png'} alt="logo"/>
        </div>
      </div>
      <div className="layout-main-container login-page">
        <div className="layout-main">
          <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">

            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
              <div className="text-center mb-5">
                <div className="text-900 text-3xl font-medium mb-3">{translate('Login')}</div>
                <span className="text-600 font-medium line-height-3">Homecasta CRM</span>
              </div>

              <form onSubmit={handleSubmit}>
                <label htmlFor="username" className="block text-900 font-medium mb-2">{translate('Username')}</label>
                <InputText id="username" type="text" className="w-full mb-3" value={username} onChange={(e) => setUsername(e.target.value)} required/>

                <label htmlFor="password" className="block text-900 font-medium mb-2">{translate('Password')}</label>
                <InputText id="password" type="password" className="w-full mb-3" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <div id="html_element"></div>
                <div className="flex align-items-center justify-content-between mb-6">
                  <div className="flex align-items-center">
                  </div>
                  <a href="/" className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Quên mật khẩu?</a>
                </div>
                <Messages ref={message}/>
                <Button label={translate('Login')} icon="pi pi-user" className="w-full" type="submit"/>
              </form>
            </div>
          </div>
        </div>
        <AppFooter/>
      </div>
    </div>
  );
}
