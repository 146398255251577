import {EntityService} from '../../../service/EntityService';

export class SentMailHistoryService extends EntityService {
  static entity = '/iam-system/email-messages';

  static resend(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/resend`, data).then(res => res.data);
  }

  static review(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/html`).then(res => res.data);
  }
}