import {ConfirmDialogService, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm} from '@iamsoftware/react-hooks';
import {CustomerService as Service} from './CustomerService';
import {Fragment, useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';
import {Card} from 'primereact/card';
import {Column} from 'primereact/column';
import {CommonService} from '../../../service/CommonService';

export const Customers = () => {
  const header = 'Danh sách khách hàng';
  const dataKey = 'partyId';

  const [servicePurchased, setServicePurchased] = useState([])
  const [prodCategories, setProdCategories] = useState([])
  useEffect(() => {
    CommonService.getProductCategory().then(({listData}) => {
      setProdCategories(listData.map((item, index) => {
        if (index === 0) {
          form.setValue({productCategoryId: item.productCategoryId})
        }
        return {value: item.productCategoryId, label: `${item.categoryName}`}
      }))
    })
  }, []);

  const form = useForm({
    fields: [
      {field: 'productCategoryId', header: 'Loại dịch vụ', className: 'md:col-3', type: 'Dropdown', DropdownProps: {options: prodCategories, filter: true}}
    ]
  })

  const _formValue = form.getValue()

  useEffect(() => {
    reloadLazyData()
  }, [_formValue?.productCategoryId]);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã khách hàng', matchMode: 'contains', minWidth: 100},
      {field: 'partyName', header: 'Tên khách hàng', matchMode: 'contains', minWidth: 200},
      {field: 'representativeName', header: 'Người đại diện', matchMode: 'contains', minWidth: 150},
      {field: 'stateProvinceGeo', header: 'Tỉnh/thành phố', matchMode: 'contains', minWidth: 150},
      {field: 'effectiveDate', header: 'Bắt đầu', matchMode: 'contains', minWidth: 150, dataType: 'date'},
      {field: 'ordered', header: 'Đã mua', matchMode: 'contains', minWidth: 150},
      {field: 'used', header: 'Đã sử dụng', matchMode: 'contains', minWidth: 150},
      {field: 'remain', header: 'Còn lại', matchMode: 'contains', minWidth: 150},
      {field: 'expireDate', header: 'Kết thúc', matchMode: 'contains', minWidth: 150, dataType: 'date'},
      {field: 'status', header: 'Trạng thái', matchMode: 'contains', minWidth: 100},
      {
        field: 'agentParty', header: 'Đại lý', matchMode: 'contains', minWidth: 200, dataType: 'custom', customCell(rowData: any): any {
          return <span>[{rowData.agentPartyTaxId}] - {rowData.agentPartyName}</span>
        }
      },
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Chi tiết', className: 'p-button-warning', command: () => doReview(item)},
          ]
          if (item.customerStatusId === 'CustCreated') {
            item.actions.push({icon: 'pi pi-check', title: 'Duyệt', className: 'p-button-success', command: () => doApprove(item)},)
            item.actions.push({icon: 'pi pi-times', title: 'Từ chối', className: 'p-button-danger', command: () => doReject(item)},)
          }
        })
      }
    },
    getList(lazyLoadEvent: any): Promise<any> {
      const formValue: any = form.getRawValue()
      let params = {...formValue};
      return Service.getList(lazyLoadEvent, params).then(data => {
        return data
      })
    }
  })


  const {render: renderDataTableAssets} = useDataTableBasic({
    dataKey: 'assetId',
    columns: [
      {field: 'categoryName', header: 'Loại dịch vụ', minWidth: 150},
      {field: 'assetName', header: 'Gói dịch vụ', minWidth: 150},
      {field: 'acquireCost', header: 'Tổng tiền', minWidth: 150, dataType: 'number'},
      {field: 'receivedDate', header: 'Ngày bắt đầu', minWidth: 150, dataType: 'date'},
      {field: 'activationValidThru', header: 'Ngày hết hạn', minWidth: 150, dataType: 'date'},
      {field: 'originalQuantity', header: 'Số lượng', minWidth: 150, dataType: 'number'},
      {field: 'usedQuantity', header: 'Đã sử dụng', minWidth: 150, dataType: 'number'},
      {field: 'quantityOnHandTotal', header: 'Còn lại', minWidth: 150, dataType: 'number'},
    ],
    indexColumnWidth: 45,
    items: servicePurchased
  })


  const {render: renderDialogCrup, view, display} = useDialogCrup({
    header: 'khách hàng',
    dataKey,
    fields: [
      {field: 'partyTaxId', header: 'Mã số thuế', className: 'md:col-6', required: true},
      {field: 'partyName', header: 'Tên khách hàng', className: 'md:col-6', required: true},
      {field: '', header: 'Địa chỉ website (đơn vị)', className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email (đơn vị)', className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại (đơn vị)', className: 'md:col-3'},
      {field: '', header: 'Số Fax (đơn vị)', className: 'md:col-3'},
      {field: 'stateProvinceGeo', header: 'Tỉnh/Thành phố', className: 'md:col-3', required: true},
      {field: 'countyGeo', header: 'Quận/Huyện', className: 'md:col-3', required: true},
      {field: 'address1', header: 'Số nhà,Phường xã', className: 'md:col-3', required: true},
      {field: 'taxAuthorityName', header: 'Cơ quan thuế', className: 'md:col-3', required: true},
      {field: 'representativeName', header: 'Tên người đại diện', className: 'md:col-3'},
      {field: 'representativeJobTitle', header: 'Chức vụ người đại diện', className: 'md:col-3'},
      {field: 'accountNumber', header: 'Số tài khoản', className: 'md:col-3'},
      {field: 'bankName', header: 'Ngân hàng', className: 'md:col-3'},
      {
        type: 'custom', body: <Fieldset legend="Dịch vụ đã mua">
          {renderDataTableAssets()}
        </Fieldset>
      },
    ]
  })

  useEffect(() => {
    if (display === false) {
      setServicePurchased([]);
    }
  }, [display]);

  const doReview = (item) => {
    if (item[dataKey]) {
      Service.get(item[dataKey]).then(async data => {
        await data?.assets.map(item => {
          item.usedQuantity = item?.originalQuantity - item?.quantityOnHandTotal;
          return item
        })
        setServicePurchased(data?.assets);
        view(data)
      })
    }
  }

  const doApprove = (item) => {
    if (item[dataKey]) {
      ConfirmDialogService.confirm('Duyệt khách hàng', 'Bạn xác nhận duyệt khách hàng này?', () => {
        Service.approve(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData()
        })
      })
    }
  }

  const doReject = (item) => {
    if (item[dataKey]) {
      ConfirmDialogService.confirm('Từ chối khách hàng', 'Bạn xác nhận từ khối khách hàng này?', () => {

      })
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        {form.render()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}