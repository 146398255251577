import {Fragment, useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';

import {Button} from 'primereact/button';
import {
    ConfirmDialogService,
    FormatDisplay,
    ToastService,
    useDataTable,
    useDialogCrup,
    useForm,
    useToolbar
} from '@iamsoftware/react-hooks';

import {RequestService as Service} from './RequestService';
import {CommonService} from '../../../service/CommonService';
import {AgentService} from '../../dai-ly/danh-sach/AgentService';
import {ProductService} from '../../danh-muc/goi-dich-vu/ProductService';
import {useCrupRequestItems} from './RequestItems';
import {ZIndexUtils} from 'primereact/utils';
import set = ZIndexUtils.set;
import {ExtensionRequest} from './ExtensionRequest';

export const Requests = () => {

    const dataKey = 'requestId';
    const [display, setDisplay] = useState(null);
    const [provinces, setProvinces] = useState([]);
    const [counties, setCounties] = useState([]);
    const [taxAuthority, setTaxAuthority] = useState([]);
    const [agents, setAgents] = useState([]);
    const [headerResult, setHeaderResult] = useState(null);
    const [totalRecords, setTotalRecords] = useState('')
    const [readOnly, setReadOnly] = useState(false)
    const [productCategory, setProductCategory] = useState([])
    const [productCategoryId, setProductCategoryId] = useState('')
    const [currenCategoryId, setCurrenCategoryId] = useState('')
    const [currenProducts, setCurrenProducts] = useState([])
    const header = `Yêu cầu`;

    useEffect(() => {
        setHeaderResult(tabHeader)
    }, [totalRecords]);

    const tabHeader = (
        <div>
            <div className="flex align-items-center justify-content-between ">
                <Fragment>
                    <span>{totalRecords} {header}</span>
                    <div>
                        <Button label="Yêu cầu mới" icon="pi pi-plus" severity="success" className="mr-2 mb-1 h-2rem"
                                size="small" onClick={() => doCreate()}/>
                        <Button label="Gia hạn" icon="pi pi-undo" severity="secondary" className="mb-1 w-7rem h-2rem"
                                size="small" onClick={() =>doReNew()}/>
                    </div>
                </Fragment>
            </div>
        </div>
    )
    useEffect(() => {
        CommonService.getProvinces().then(data => {
            setProvinces(data.listData.map(item => {
                return {value: item.toGeoId, label: item.geoName}
            }));
        });
        AgentService.getTreeList('N').then(data => {
            const _parents = Service.dataTreeTableToTreeSelect(data.orgTree, item => {
                return `[${item.pseudoId}] - ${item.partyName}`;
            });
            setAgents(_parents);
        });
        CommonService.getProductCategory().then(data => {
            setProductCategory(data.listData.map(item => {
                return {value: item.productCategoryId, label: item.categoryName}
            }))
        })
    }, []);

    const {render: renderDataTable, reloadLazyData} = useDataTable({
        tableHeader: headerResult,
        dataKey,
        columns: [
            {
                field: 'requestId',
                header: 'Mã yêu cầu',
                width: 120,
                matchMode: 'contains',
                dataType: 'custom',
                customCell(rowData: any): any {
                    return <span className="underline text-primary" onDoubleClick={() => doUpdate(true, rowData)}
                                 title="Double Click để xem chi tiết">{rowData.requestId}</span>
                }
            },
            {field: 'requestTypeEnum', header: 'Loại yêu cầu', width: 120, matchMode: 'contains'},
            {field: 'customerName', header: 'Tên khách hàng', width: 450, matchMode: 'contains'},
            {field: 'partyTaxId', header: 'Mã số thuế', width: 160, matchMode: 'contains'},
            {field: 'representativeName', header: 'Tên người liên hệ', width: 150, matchMode: 'contains'},
            {field: 'requestDate', header: 'Ngày tạo', width: 120, matchMode: 'contains', dataType: 'date'},
            {field: 'agentPseudoId', header: 'Mã đại lý', width: 150, matchMode: 'contains'},
            {field: 'categoryName', header: 'Loại dịch vụ', width: 150, matchMode: 'contains'},
            {field: 'status', header: 'Trạng thái', width: 120, matchMode: 'contains'},
        ],
        indexColumnWidth: 45,
        actionColumnWidth: 120,
        initActions(items: Array<any>) {
            if (items) {
                items.forEach(item => {
                    if (item.statusId === 'ReqCreated') {
                        item.actions = [
                            {
                                icon: 'pi pi-pencil',
                                title: 'Chỉnh sửa',
                                className: 'p-button-warning',
                                command: () => doUpdate(false, item)
                            },
                        ]
                        item.actions.push({
                                icon: 'pi pi-check',
                                title: 'Phê duyệt',
                                className: 'p-button-success',
                                command: () => doApprove(item)
                            },
                            {
                                icon: 'pi pi-times',
                                title: 'Từ chối',
                                className: 'p-button-danger',
                                command: () => doReject(item)
                            },)
                    }else {
                        item.actions = [
                            {
                                icon: 'pi pi-eye',
                                title: 'Xem',
                                className: 'p-button-secondary',
                                command: () => doUpdate(true, item)
                            },
                        ]
                    }
                })

            }
        },
        getList: lazyLoadEvent => {
            return Service.getList(lazyLoadEvent).then(result => {
                setTotalRecords(result.totalRecords)
                return result;
            });
        },
    });
    const {renderProducts, items, setItems, itemProductDelIds} = useCrupRequestItems({
        display,
        readOnly,
        productCategoryId
    });
    const {renderReNew, doReNew} = ExtensionRequest({reloadLazyData})
    const productForm = useForm({
        fields: [
            {
                field: 'effectiveDate',
                header: 'Ngày bắt đầu sử dụng',
                className: 'md:col-6',
                InputTextProps: {type: 'date'}
            },
            {
                field: 'productCategoryId',
                header: 'Loại dịch vụ',
                className: 'md:col-6',
                type: 'Dropdown',
                DropdownProps: {options: productCategory}
            },
            {type: 'custom', body: renderProducts()}
        ]
    })
    // const representativeForm = useForm({
    //   fields: [
    //     {field: 'representativeName', header: 'Tên người đại diện', className: 'md:col-6'},
    //     {field: 'representativeJobTitle', header: 'Chức vụ người đại diện', className: 'md:col-6'},
    //   ]
    // })
    const {render: renderDialogCrup, create, update, view, form} = useDialogCrup({
        header,
        dataKey,
        width: '100rem',
        fields: [
            {
                field: 'partyTaxId',
                header: 'Mã số thuế khách hàng',
                required: true,
                InputTextProps: {maxLength: 15, keyfilter: 'int'},
                className: 'md:col-4',
            },
            {
                type: 'custom',
                className: 'md:col-2 mt-button-info',
                body: <Button label="Lấy thông tin" outlined disabled={readOnly} size="small"
                              onClick={() => getPartyInfo(form.getRawValue().partyTaxId)}></Button>
            },
            {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-6'},
            {field: 'address1', header: 'Địa chỉ', className: 'md:col-3'},
            {
                field: 'stateProvinceGeoId',
                header: 'Tỉnh/Thành phố',
                required: true,
                type: 'Dropdown',
                DropdownProps: {options: provinces, filter: true},
                className: 'md:col-3'
            },
            {
                field: 'countyGeoId',
                header: 'Quận/Huyện',
                required: true,
                type: 'Dropdown',
                DropdownProps: {options: counties, filter: true},
                className: 'md:col-3'
            },
            {
                field: 'taxAuthorityId',
                header: 'Cơ quan thuế',
                type: 'Dropdown',
                DropdownProps: {options: taxAuthority, filter: true},
                className: 'md:col-3'
            },
            {field: 'emailAddress', header: 'Email khách hàng',required: true, className: 'md:col-3'},
            {field: 'phoneNumber', header: 'Số điện thoại', className: 'md:col-3'},
            {field: 'bankName', header: 'Tên ngân hàng', className: 'md:col-3'},
            {field: 'bankAccount', header: 'Số tài khoản', className: 'md:col-3'},
            {field: 'representativeName', header: 'Tên người đại diện', className: 'md:col-3'},
            {field: 'representativeJobTitle', header: 'Chức vụ người đại diện', className: 'md:col-3'},
            {
                field: 'agentPartyId',
                header: 'Đại lý',
                required: true,
                type: 'TreeSelect',
                TreeSelectProps: {options: agents, filter: true},
                className: 'md:col-6'
            },
            {
                type: 'custom', body: <Fieldset legend='Gói dịch vụ'>
                    {productForm.render()}
                </Fieldset>
            }
        ],
        createItem: async item => {
            return Service.newAgreement(await inject(item));
        },
        updateItem: async (id, item) => {
            if (productData?.productCategoryId !== currenCategoryId){
                currenProducts.map(prevItem =>{
                    itemProductDelIds.push(prevItem.orderItemSeqId)
                })
            }
            return Service.update(id, await inject(item));
        },
        reloadLazyData
    });
    const productData = productForm.getValue()
    const inject = async item => {
        const data = Object.assign(item, productData);
        data.itemProducts = items
        if (itemProductDelIds) {
            data.itemProductDelIds = itemProductDelIds
        }
        return data;
    }
    const {stateProvinceGeoId} = form.getValue();
    useEffect(() => {
        if (stateProvinceGeoId) {
            CommonService.getCounties(stateProvinceGeoId).then(data => {
                setCounties(data.listData.map(item => {
                    return {value: item.toGeoId, label: item.geoName}
                }));
            });
            CommonService.getTaxAuthority(stateProvinceGeoId).then(data => {
                setTaxAuthority(data.listData.map(item => {
                    return {value: item.taxAuthorityId, label: item.description}
                }));
            });
        }
    }, [stateProvinceGeoId]);
    const doCreate = () => {
        setReadOnly(false);
        setDisplay(Date.now());
        setItems([])
        productForm.setValue({
            effectiveDate: FormatDisplay.date(Date.now(), 'YYYY-MM-DD'),
            productCategoryId: productCategory[0]?.value
        });
        create();
        form.setValue({})
    }
    useEffect(() => {
        if (productData?.productCategoryId) {
            setProductCategoryId(productData.productCategoryId)
            setItems([])
            if (productData?.productCategoryId === currenCategoryId){
                setItems(currenProducts)
            }
        }
    }, [display, productData?.productCategoryId]);

    // Xử lý thay đổi loại dịch vụ
    // useEffect(() => {
    //     if (productData?.productCategoryId && productData?.productCategoryId === currenCategoryId){
    //         setItems(currenProducts)
    //     }
    //     console.log(123)
    // }, [productData]);

    const doUpdate = (doView?: boolean, rowData?: any) => {
        setItems([])
        let _dataKey;
        if (rowData && rowData[dataKey]) {
            _dataKey = rowData[dataKey];
        }
        if (_dataKey) {
            Service.getDetail(_dataKey).then(async data => {
                if (data.effectiveDate) {
                    data.effectiveDate = FormatDisplay.date(data.effectiveDate, 'YYYY-MM-DD')
                }
                setCurrenProducts(data.itemProducts)
                setCurrenCategoryId(data.productCategoryId)
                if (doView === true) {
                    setReadOnly(true)
                    setDisplay(Date.now());
                    productForm.setValue(data)
                    setItems(data.itemProducts)
                    view(data);
                } else {
                    setDisplay(Date.now());
                    productForm.setValue(data)
                    setReadOnly(false)
                    setItems(data.itemProducts)
                    update(data);
                }
            });
        }
    }

    const getPartyInfo = (input) => {
        const _value = form.getRawValue()
        if (input) {
            CommonService.getPartyByTax(input).then(({data}) => {
                let newInfo = {..._value, ...data}
                form.setValue(newInfo)
            })
        }
    }

    const doApprove = (input) => {
        ConfirmDialogService.confirm('Phê duyệt yêu cầu', 'Xác nhận phê duyệt yêu cầu này?', () => {
            Service.approve(input.requestId).then(() => {
                    ToastService.success();
                    reloadLazyData();
                }
            )
        })
    }
    const doReject = (input) => {
        ConfirmDialogService.confirm('Từ chối yêu cầu', 'Xác nhận từ chối yêu cầu này?', () => {
            Service.reject(input.requestId).then(() => {
                    ToastService.success();
                    reloadLazyData();
                }
            )
        })
    }
    return (
        <div className="grid">
            <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
                {renderDataTable()}
            </div>
            {renderDialogCrup()}
            {renderReNew()}
        </div>
    );
}