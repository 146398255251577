import {Route, Routes} from 'react-router-dom';

import {Products} from './goi-dich-vu';
import {TaxAuthorities} from './co-quan-thue';

export const Category = () => {

  return (
    <Routes>
      <Route path="goi-dich-vu" element={<Products/>}/>
      <Route path="co-quan-thue" element={<TaxAuthorities/>}/>
    </Routes>
  );
}