import axios from './axios';

import {baseURL} from '../config';

export abstract class Service {

  static entity: string;

  static baseURL = baseURL;

  static axios = axios;

  static dataTreeTableToTreeSelect(items: Array<any>, format: any) {
    if (items && items.length) {
      items.forEach(item => {
        item.label = format(item.data);
        this.dataTreeTableToTreeSelect(item.children, format);
      });
    }
    return items;
  }

  static dataTreeTableToOptions(items: Array<any>, format: any) {
    const options = [];
    if (items && items.length) {
      items.forEach(item => {
        options.push({value: item.key, label: format(item.data)});
        options.push(...this.dataTreeTableToOptions(item.children, format));
      });
    }
    return options;
  }

  static toFormData(data: any): FormData {
    const formData = new FormData();
    const doAppend = (key, value) => {
      if (typeof value === 'number') {
        formData.append(key, value.toString());
      } else {
        formData.append(key, value || '');
      }
    }
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key]) || (data[key] instanceof FileList && data[key].length > 1)) {
        for (const value of data[key]) {
          doAppend(key, value);
        }
      } else {
        doAppend(key, data[key]);
      }
    });
    return formData;
  }

}
