import {useEffect, useState} from 'react';
import moment from 'moment';

import {Chart} from 'primereact/chart';

import {DashboardService as Service} from './DashboardService';

import {useDataTableBasic} from '../../shared/hooks/DataTableBasic';
import {AppStorage} from '../../service/UserStorage';
import {ChangePassword} from './ChangePassword';
import {InputNumber} from 'primereact/inputnumber';

export default function Dashboard() {

  const [newUser, setNewUser] = useState(0);
  const [newUserInWeek, setNewUserInWeek] = useState(0);

  const [totalCashIn, setTotalCashIn] = useState(0);
  const [totalCashInPrevMonth, setTotalCashInPrevMonth] = useState(0);

  const [newAccount, setNewAccount] = useState(0);
  const [newAccountInMonth, setNewAccountInMonth] = useState(0);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartDataTotal, setChartDataTotal] = useState({});
  const [chartOptionsTotal, setChartOptionsTotal] = useState({});
  const [displayChangePassword, setDisplayChangePassword] = useState(null)
  const [textValue, setTextValue] = useState(6)
  const [textValue1, setTextValue1] = useState(6)

  const [items, setItems] = useState([]);
  // const [lineData, setLineData] = useState(null);
  const documentStyle = getComputedStyle(document.documentElement);

  useEffect(() => {
  }, []);
  const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(new Date(date).getDate());
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  useEffect(() => {
    Service.getCusInWeek().then(data =>{
      setNewUser(data.total);
      setNewUserInWeek(data.number)
    })
    Service.getCusPastMonth().then(data =>{
      setTotalCashInPrevMonth(data.number)
    })
    Service.getAgentInMonth().then(data =>{
      setNewAccount(data.total);
      setNewAccountInMonth(data.number)
    })
  }, []);


  const {render: renderDataTable} = useDataTableBasic({
    columns: [
      {field: 'phoneNumber', header: 'Tài khoản'},
      {
        field: 'type', header: 'Loại giao dịch', dataType: 'custom', customCell(rowData: any): any {
          switch (rowData.type) {
            case 1:
              return "Nạp tiền";
              break;
            case 2:
              return "Rút tiền";
              break;
            case 3:
              return "Thanh toán hóa đơn";
              break;
            case 5:
              return "Chuyển tiền";
              break;
          }
        }
      },
      {field: 'totalRow', header: 'Số tiền', dataType: 'number'},
      {field: 'day', header: 'Thời gian', dataType: 'date', style: {minWidth: '160px', maxWidth: '160px'}}
    ],
    items
  });
  useEffect(() => {
    if (textValue){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'Khách hàng',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getCusInPeriod(textValue).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartData(lineData)
          setChartOptions(lineOptions)
        }
      })
    }
  }, [textValue]);

  useEffect(() => {
    if (textValue1){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'Tổng tiền',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getTotalInPeriod(textValue1).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartDataTotal(lineData)
          setChartOptionsTotal(lineOptions)
        }
      })
    }
  }, [textValue1]);

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Tổng SL khách hàng</span>
              <div className="text-900 font-medium text-xl">{newUser}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-users text-blue-500 text-xl"/>
            </div>
          </div>
          <span className="text-green-500 font-medium">{newUserInWeek} </span>
          <span className="text-500">trong tuần này</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">SL khách hàng mới</span>
              <div className="text-900 font-medium text-xl">{totalCashIn}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-money-bill text-orange-500 text-xl"/>
            </div>
          </div>
          <span className="text-500">Tháng trước</span>
          <span className="text-green-500 font-medium"> {totalCashInPrevMonth}</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">SL đại lý con</span>
              <div className="text-900 font-medium text-xl">{newAccount}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-link text-cyan-500 text-xl"/>
            </div>
          </div>
          <span className="text-green-500 font-medium">{newAccountInMonth}  </span>
          <span className="text-500">trong tháng này</span>
        </div>
      </div>
      <div className="col-12 xl:col-6">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Số lượng khách hàng mới theo thời gian</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue} onChange={(e) => setTextValue(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartData} options={chartOptions}/>
          </div>
        </div>
      </div>
      <div className="col-12 xl:col-6">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Doanh thu bán hàng mới theo thời gian</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue1} onChange={(e) => setTextValue1(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartDataTotal} options={chartOptionsTotal}/>
          </div>
        </div>
      </div>
      <ChangePassword display={displayChangePassword} setDisplay={setDisplayChangePassword}/>
    </div>
  );
}
