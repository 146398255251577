import axios from './axios';

import {baseURL} from '../config';

export abstract class Service {

  static entity: string;

  static baseURL = baseURL;

  static axios = axios;
  static toFormData(data: any): FormData {
    const formData = new FormData();
    const doAppend = (key, value) => {
      if (typeof value === 'number') {
        formData.append(key, value.toString());
      } else {
        formData.append(key, value || '');
      }
    }
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key]) || (data[key] instanceof FileList && data[key].length > 1)) {
        for (const value of data[key]) {
          doAppend(key, value);
        }
      } else {
        doAppend(key, data[key]);
      }
    });
    return formData;
  }
}
