import {useEffect, useState, Fragment} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {TreeTable} from 'primereact/treetable';
import {ConfirmDialogService, FormatDisplay, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {AgentService as Service} from './AgentService';
import {CommonService} from '../../../service/CommonService';
import {PartyService} from '../../../service/PartyService';

interface Props {
  beCreate?: boolean
}

export const AgentTree = (props: Props) => {

  const header = 'Đại lý / CTV';
  const dataKey = 'partyId';

  const [nodes, setNodes] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [viewing, setViewing] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parents, setParents] = useState([]);
  const [agreementItems, setAgreementItems] = useState([]);
  const [deleteAgreementPriceIds, setDeleteAgreementPriceIds] = useState([]);

  const [relatedIdentifyType, setRelatedIdentifyType] = useState('');
  const [partyTypeEnumIds, setPartyTypeEnumIds] = useState([])
  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
    CommonService.getPartyType().then(data => {
      setPartyTypeEnumIds(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }))
    })
    load();
  }, []);
  useEffect(() => {
    if (props.beCreate) {
      doCreate();
    }
  }, [props.beCreate]);
  useEffect(() => {
    if (agreementItems) {
      agreementItems.forEach(async (item, index) => {
        if (!item._id) {
          item._id = `${item.agreementId}_${Date.now()}_${index}`;
        }
        if (item?.vendorParties) {
          item.vendorPseudoIds = item.vendorParties.label
        }
        return item;
      })
    }
  }, [agreementItems?.length]);
  const load = () => {
    Service.getTreeList().then(data => {
      setNodes(data.orgTree);
    });
    Service.getTreeList('N').then(data => {
      const _parents = Service.dataTreeTableToTreeSelect(data.orgTree, item => {
        return `[${item.pseudoId}] - ${item.partyName}`;
      });
      setParents(_parents);
    });
  }

  const getByTaxCode = () => {
    const _value = form.getRawValue();
    if (_value.partyTaxId) {
      PartyService.getByTaxCode(_value.partyTaxId).then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          form.setValue(_value);
        }
      });
    }
  }

  const {render: renderDialogCrup, create, update, view, form, formMethod, display} = useDialogCrup({
    header,
    dataKey,
    fields: [
      // {type: 'custom', className: 'md:col-'},

      {
        field: 'partyTaxId', header: 'Mã số thuế Đại Lý', required: true, InputTextProps: {maxLength: 15, keyfilter: 'int'}, className: 'xyxy md:col-3'
      },
      {
        field: 'buttonSearch', type: 'custom', className: 'zzz',
        body: <Button className="" style={{marginTop: '1.6rem', height: '2.4rem'}} size="small" icon="pi pi-search" disabled={viewing} onClick={() => getByTaxCode()}></Button>
      },
      {
        field: 'partyTypeEnumId', header: 'Loại đại lý', required: true, type: 'Dropdown', DropdownProps: {options: partyTypeEnumIds}, className: 'md:col-3'
      },
      {
        field: 'pseudoId', header: 'Mã đại lý', required: true, className: 'md:col-3'
      },
      {field: 'username', header: 'Tài khoản', required: true, InputTextProps: {maxLength: 50}, disabled: 'View', className: 'md:col-3'},
      {field: 'partyName', header: 'Tên đại lý', required: true, className: 'md:col-3'},
      {field: 'address1', header: 'Địa chỉ', required: true, className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-3'},
      {
        field: 'representativeName', header: 'Người đại diện',required: true, InputTextProps: {maxLength: 500}, className: 'md:col-3',
      },
      {
        field: 'representativeJobTitle', header: 'Chức vụ', InputTextProps: {maxLength: 500}, className: 'md:col-3'
      },
      {field: 'parentPartyId', header: 'Đại lý cha', type: 'TreeSelect', TreeSelectProps: {options: parents, filter: true}, className: 'md:col-3'},
    ],
    createItem: async item => {
      return Service.createAgent(await inject(item));
    },
    updateItem: async (id, item) => {
      return Service.updateAgent(id, await inject(item));
    },
    reloadLazyData: load,
    useSaveSplitButton:false
  });

  const inject = async item => {
    if (deleteAgreementPriceIds) {
      item.deleteAgreementPriceIds = deleteAgreementPriceIds;
    }
    if (agreementItems) {
      item.agreementPrices = agreementItems;
    }
    return item;
  }

  useEffect(() => {
    if (display === false) {
      setAgreementItems([])
    }
  }, [display]);

  const {partyTaxId, pseudoId, stateProvinceGeoId, relatedIdTypeEnumId} = form.getValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  useEffect(() => {
    let _relatedIdentifyType = '-';
    if (relatedIdTypeEnumId) {
      _relatedIdentifyType = PartyService.identifyTypes.find(it => it.value === relatedIdTypeEnumId)?.label || '-';
    }
    setRelatedIdentifyType(_relatedIdentifyType);
  }, [relatedIdTypeEnumId]);


  const doCreate = () => {
    setAgreementItems([])
    create();
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedNodeKey) {
      Service.getDetail(selectedNodeKey).then(data => {
        if (data.agreementPrices) {
          setAgreementItems(data.agreementPrices)
        }
        if (data.relatedIssuedDate) {
          data.relatedIssuedDate = FormatDisplay.date(data.relatedIssuedDate, 'YYYY-MM-DD')
        }
        if (doView === true) {
          setViewing(true)
          view(data);
        } else {
          setViewing(false)
          update(data);
        }
      });
    }
  }

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Tạo mới" icon="pi pi-plus" className='ml-2' severity="success" size="small" onClick={doCreate}/>
      <Button label="Xem" icon="pi pi-eye" className='ml-2' severity="info" size="small" onClick={() => doUpdate(true)} disabled={!selectedNodeKey}/>
      <Button label="Chỉnh sửa" icon="pi pi-pencil" className='ml-2' severity="warning" size="small" onClick={() => doUpdate(false)} disabled={!selectedNodeKey}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: ''}}>
        <TreeTable value={nodes} selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={e => setSelectedNodeKey(e.value)}
                   scrollable scrollHeight="calc(100vh - 23rem)">
          <Column field="pseudoId" header="Mã đại lý" expander></Column>
          <Column field="partyName" header="Tên đại lý"></Column>
          <Column field="roleTypeDesc" header="Vai trò"></Column>
          <Column field="partyRank" header="Cấp đại lý" style={{width: '10rem'}}></Column>
        </TreeTable>
      </div>
      {renderDialogCrup()}
    </div>
  );
}