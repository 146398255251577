import {ConfirmDialogService, useDataTable, useDialogCrup, useForm} from '@iamsoftware/react-hooks';
import {CustomerAccountService as Service} from './CustomerAccountService';
import {SelectButton} from 'primereact/selectbutton';
import {useEffect, useState} from 'react';
import {CommonService} from '../../../service/CommonService';
import {ToastService} from '../../../AppToast';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

export const CustomerAccounts = () =>{
  const header = 'Tài khoản khách hàng';
  const dataKey = 'partyId';
  const [prodCategories,setProdCategories] = useState([])
  const [requiredParams, setRequiredParams] = useState(null);
  const [disabled, setDisabled] = useState('N');


  useEffect(() => {
    CommonService.getProductCategory().then(({listData}) =>{
      setProdCategories(listData.map((item,index) =>{
        if (index === 0){
          form.setValue({productCategoryId: item.productCategoryId})
        }
        return {value: item.productCategoryId, label:`${item.categoryName}`}
      }))
    })
  }, []);


  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: header,
    dataKey,
    columns: [
      {field:'pseudoId', header:'Mã số thuế', matchMode:'contains',minWidth: 150},
      {field:'userAccountPrefix', header:'Tên đăng nhập', matchMode:'contains',minWidth: 150},
      {field:'partyName', header:'Tên khách hàng', matchMode:'contains',minWidth: 150},
      {field:'toPseudoId', header:'Mã đại lý', matchMode:'contains',minWidth: 150},
      {field:'toPartyName', header:'Tên đại lý', matchMode:'contains',minWidth: 150},
    ],
    indexColumnWidth:45,
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-refresh', title: 'Reset mật khẩu', className: 'p-button-secondary', command: () => update(item)},
          ]
          if (item.statusId === 'CpaActive'){
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doLock(item)},)
          }else{
            item.actions.push({icon: 'pi pi-unlock', title: 'Mở khóa', className: 'p-button-success', command: () => doUnlock(item)},)
          }
        })
      }
    },
    getList(lazyLoadEvent: any): Promise<any> {
      const formValue: any = form.getRawValue()
      let params = {...formValue,disabled: disabled};
      return Service.getList(lazyLoadEvent, params).then();
    },
  })

  const form = useForm({
    fields:[
      {field:'productCategoryId', header:'Loại dịch vụ', className:'md:col-3',type:'Dropdown', DropdownProps:{options:prodCategories, filter:true}}
    ]
  })

  const _formValue = form.getValue()

  const {render: renderDialogCrup, update} = useDialogCrup({
    header:'!Reset mật khẩu',
    dataKey,
    fields:[
      {field:'password', header:'Mật khẩu mới', required: true, className:'md:col-12'}
    ],
    width:'40rem',
    updateItem(id: string, item: any): Promise<any> {
      let data = {...item, ..._formValue}
      return Service.reset(id, data).then()
    }
  })


  useEffect(() => {
    reloadLazyData()
  }, [_formValue?.productCategoryId, disabled]);

  const doLock = (item) =>{
    if (item[dataKey]){
      ConfirmDialogService.confirm('Xác nhận khóa tài khoản','Bạn có muốn khóa tài khoản này không?',()=>{
        Service.lockAcc(item[dataKey],_formValue).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doUnlock = (item) =>{
    if (item[dataKey]){
      ConfirmDialogService.confirm('Xác nhận mở khóa tài khoản','Bạn có muốn mở khóa tài khoản này không?',()=>{
        Service.unlockAcc(item[dataKey],_formValue).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  // const doReset = (item) =>{
  //   if (item[dataKey]){
  //     ConfirmDialogService.confirm('Xác nhận reset tài khoản','Bạn có muốn reset tài khoản này không?',()=>{
  //       Service.reset(item[dataKey]).then(()=>{
  //         ToastService.success();
  //         reloadLazyData();
  //       })
  //     })
  //   }
  // }

  const clearAndSwitch = (data) => {
    setDisabled(data);
  };

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={disabled} options={statuses} onChange={e => clearAndSwitch(e.value)}/>
      </div>
      <div className="col-12">
        {form.render()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 12rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}