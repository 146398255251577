import {Route, Routes} from 'react-router-dom';

import {AgentTree} from './danh-sach';

export const Agents = () => {

  return (
    <Routes>
      <Route path="danh-sach" element={<AgentTree/>}/>
      <Route path="danh-sach/them-moi" element={<AgentTree beCreate={true}/>}/>
    </Routes>
  );
}