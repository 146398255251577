import {EntityService} from '../../../service/EntityService';

export class AgentService extends EntityService {

  static entity = 'iam-party/agents';

  static getTreeList(includeAll?: string): Promise<any> {
    return this.axios.get(`${this.entity}/tree-list`, {params: {includeAll}}).then(res => res.data);
  }
  static getDetail(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/detail`).then(res => res.data);
  }
  static createAgent(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create`, data).then(res => res.data);
  }
  static updateAgent(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/update`, data).then(res => res.data);
  }

}