import {EntityService} from '../../../service/EntityService';

export class AgentAccountService extends EntityService {

  static entity = '/iam-system/user-accounts/agents';

  static lock(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/lock`).then(res => res.data);
  }

  static unlock(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/unlock`).then(res => res.data);
  }
  static reset(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/reset-password`, data).then(res => res.data);
  }
}