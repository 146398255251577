import {Service} from './Service';

export abstract class EntityService extends Service {

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static find(term: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term}}).then(res => res.data);
  }

  static get(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/detail`).then(res => res.data);
  }

  static create(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create`, data).then(res => res.data);
  }

  static update(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/update`, data).then(res => res.data);
  }

  static delete(id: string, params?: any): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/delete`, {params}).then(res => res.data);
  }

  static disable(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/disable`).then(res => res.data);
  }

  static enable(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/enable`).then(res => res.data);
  }

  static lock(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/lock`).then(res => res.data);
  }

  static unlock(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/unlock`).then(res => res.data);
  }

}
