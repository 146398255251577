import {EntityService} from '../../../service/EntityService';

export class RequestService extends EntityService {

  static entity = '/iam-sales/requests';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getDetail(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/detail`).then(res => res.data);
  }

  static newAgreement(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/new-agreement`, data).then(res => res.data);
  }

  static approve(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/approve`).then(res => res.data);
  }
  static reject(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/reject`).then(res => res.data);
  }

  static reNew(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/renew`, data).then(res => res.data);
  }


}