import {Fragment, useEffect, useState} from 'react';
import {SelectButton} from 'primereact/selectbutton';

import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {ProductService as Service} from './ProductService';
import {CommonService} from '../../../service/CommonService';
import {Button} from 'primereact/button';

export const Products = () => {

  const header = 'Gói dịch vụ';
  const dataKey = 'productId';
  const statuses = [
    {value: 'N', label: 'Đang hoạt động'},
    {value: 'Y', label: 'Ngừng hoạt động'}
  ];
  const [statusId, setStatusId] = useState(statuses[0].value);
  const [productClassTypes, setProductClassTypes] = useState([]);
  const [amountUom, setAmountUom] = useState([]);
  const [productCategory, setProductCategory] = useState([])
  const [headerResult, setHeaderResult] = useState(null);
  const [totalRecords, setTotalRecords] = useState('')

  useEffect(() => {
    setHeaderResult(tabHeader)
    reloadLazyData()
  }, [totalRecords, statusId]);

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{totalRecords} {header}</span>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => create()}/>
        </Fragment>
      </div>
    </div>
  )

  useEffect(() => {
    CommonService.getProductClassType().then(data => {
      setProductClassTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description, amountUoms: item.amountUoms}
      }));
    });
    CommonService.getProductCategory().then(data => {
      setProductCategory(data.listData.map(item => {
        return {value: item.productCategoryId, label: item.categoryName}
      }))
    })
  }, []);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: 'categoryName', header: 'Loại sản phẩm', width: 150, matchMode: 'contains'},
      {field: 'pseudoId', header: 'Mã gói', width: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Tên gói dịch vụ', width: 180, matchMode: 'contains'},
      {field: 'amountValue', header: 'Số lượng', width: 150, matchMode: 'contains'},
      {field: 'price', header: 'Giá', width: 150, matchMode: 'numeric' as any, dataType: 'number'},
    ],
    indexColumnWidth:30,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent, statusId).then(data => {
        setTotalRecords(data.totalRecords)
        return data
      });
    },
    actionColumnWidth: 90,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item)},
          ]
          if (statusId === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa gói dịch vụ', className: 'p-button-danger', command: () => doDisable(item)},)
          } else if (statusId==='Y') {
            item.actions.push({icon: 'pi pi-unlock', title: 'Mở khóa gói dịch vụ', className: 'p-button-success', command: () => doEnable(item)},)
          }
        })

      }
    },
  });

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header,
    dataKey,
    width: '80rem',
    fields: [
      {field: 'pseudoId', header: 'Mã gói dịch vụ', required: true, InputTextProps: {maxLength: 15}, className: 'md:col-6'},
      {field: 'productName', header: 'Tên gói dịch vụ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-6'},
      {field: 'productClassEnumId', header: 'Loại sản phẩm', required: true, type: 'Dropdown', DropdownProps: {options: productClassTypes}, className: 'md:col-6'},
      {field: 'productCategoryId', header: 'Loại dịch vụ', required: true, type: 'Dropdown', DropdownProps: {options: productCategory}, className: 'md:col-6'},
      {field: 'amountFixed', header: 'Số lượng', required: true, type: 'InputNumber', InputNumberProps: {min: 1}, className: 'md:col-6'},
      {field: 'price', header: 'Giá', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-6'},
      {field: 'amountUomId', header: 'Đơn vị', type: 'Dropdown', required:true, DropdownProps: {options: amountUom}, className: 'md:col-6'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });
  const {productClassEnumId} = form.getValue();
  useEffect(() => {
    productClassTypes.map(productClassType => {
      if (productClassType.value === productClassEnumId) {
        setAmountUom(productClassType.amountUoms.map(item => {
          return {value: item.uomId, label: item.description}
        }));
      }
    })
  }, [productClassEnumId])
  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = (input) => {
    Service.get(input.productId).then(data => {
      update(data);
    });
  }

  const doDisable = (data) => {
    ConfirmDialogService.confirm('Khóa gói dịch vụ', `Xác nhận khóa ${data.productName} ?`, () => {
      Service.disable(data.productId).then(() => {
        ToastService.success();
        reloadLazyData();
      });

    })
  }
  const doEnable = (data) => {
    ConfirmDialogService.confirm('Mở khóa gói dịch vụ', `Xác nhận mở khóa ${data.productName} ?`, () => {
      Service.enable(data.productId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }
  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}