import {useEffect, useState} from 'react';

import {BreadCrumb} from 'primereact/breadcrumb';
import {MegaMenu} from 'primereact/megamenu';

import {BehaviorSubject} from 'rxjs';

const breadcrumbs = new BehaviorSubject(null);
export const BreadcrumbsService = {
  setBreadcrumbs: data => breadcrumbs.next(data),
  getBreadcrumbs: () => breadcrumbs.asObservable()
};

const actions = new BehaviorSubject(null);
export const ActionsService = {
  setActions: data => actions.next(data),
  getActions: () => actions.asObservable()
};

export const AppBreadCrumb = () => {

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    BreadcrumbsService.getBreadcrumbs().subscribe((data) => {
      setBreadcrumbs(data);
    });
    ActionsService.getActions().subscribe((data) => {
      setActions(data);
    });
  }, []);

  const home = {icon: 'pi pi-home', url: '/'};

  return (
    <div className="layout-breadcrumb flex justify-content-between flex-wrap">
      <div className="flex align-items-center justify-content-center">
        <BreadCrumb model={breadcrumbs} home={home}/>
      </div>
      <div className="flex align-items-center justify-content-center">
        <MegaMenu model={actions}/>
      </div>
    </div>
  );
}
