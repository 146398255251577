import {EntityService} from '../../../service/EntityService';

export class ProductService extends EntityService {

  static entity = 'iam-main/products';

  static getProductList(lazyLoadEvent: string, disabled:string, id:string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/list?disabled=${disabled}&productCategoryId=${id}&lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }
  static getDetail(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/detail`).then(res => res.data);
  }
  static getList(lazyLoadEvent: string,disabled:string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/list?disabled=${disabled}&lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }
}