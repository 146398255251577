import {EntityService} from '../../../service/EntityService';

export class CustomerAccountService extends EntityService {
  static entity = '/iam-system/user-accounts/customers';

  static reset(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/reset-password`, data).then(res => res.data);
  }

  static lockAcc(id: string, data:any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/lock`, data).then(res => res.data);
  }

  static unlockAcc(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/unlock`, data).then(res => res.data);
  }

}