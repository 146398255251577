import {Service} from '../../service';

export class DashboardService extends Service {

  static entity = '/iam-system/dashboard';

  static getDashboardData(): Promise<any> {
    return this.axios.get(`${this.entity}/v1/dashboard`).then(res => res.data);
  }
  static changePassword(data:any): Promise<any> {
    return this.axios.post(`/iam-auth/change-password`,data).then(res => res.data);
  }

  static getCusInWeek(): Promise<any> {
    return this.axios.get(`${this.entity}/cus-of-week`).then(res => res.data);
  }
  static getCusPastMonth(): Promise<any> {
    return this.axios.get(`${this.entity}/cus-past-month`).then(res => res.data);
  }
  static getCusInPeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/cus-in-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }
  static getTotalInPeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/total-in-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }
  static getAgentInMonth(): Promise<any> {
    return this.axios.get(`${this.entity}/agent-in-month`).then(res => res.data);
  }
}
