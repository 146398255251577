import {Service} from '../../service';

export class LoginService extends Service {

  static entity: string = 'iam-auth';

  static getToken(): Promise<any> {
    return this.axios.get(`${this.entity}/token`).then(res => res.data);
  }

  static getApiKey(): Promise<any> {
    return this.axios.get(`${this.entity}/api-key`).then(res => res.data);
  }

  static login(username: string, password: string): Promise<any> {
    return this.axios.post(`${this.entity}/login`, {username, password}).then(res => res.data);
  }

  static logout(): Promise<any> {
    return this.axios.post(`${this.entity}/logout`).then(res => res.data);
  }

  static changePassword(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/change-password`, data).then(res => res.data);
  }

  static resetPassword(username: string, emailAddress: string): Promise<any> {
    return this.axios.put(`${this.entity}/reset-password`, {username, emailAddress}).then(res => res.data);
  }

  static getMe(): Promise<any> {
    return this.axios.get(`${this.entity}/me`).then(res => res.data);
  }


}
