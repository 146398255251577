import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/layout/flags/flags.css';
import './assets/layout/layout.scss';
import './App.css';

import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Routes} from 'react-router-dom';

import {AppConfirmDialog, AppToast, DefaultConf, ScrollToTop} from '@iamsoftware/react-hooks';
import {AppProgressBar} from './AppProgressBar';
import App from './app';
import Login from './pages/login';

DefaultConf.DataTable.IndexColumnHeader = '#';
DefaultConf.DataTable.ItemsField = 'listData';
document.documentElement.style.fontSize = '14px';

ReactDOM.render(
  <HashRouter>
    <AppToast/>
    <AppConfirmDialog/>
    <AppProgressBar/>
    <ScrollToTop>
      <Routes>
        <Route path="login" element={<Login/>}/>
        <Route path="*" element={<App/>}/>
      </Routes>
    </ScrollToTop>
  </HashRouter>,
  document.getElementById('root')
);

export * from './shared/language';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
