import {Fragment, useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';

import {Button} from 'primereact/button';
import {
  ConfirmDialogService,
  FormatDisplay,
  ToastService,
  useDataTable,
  useDialogCrup,
  useForm,
  useToolbar
} from '@iamsoftware/react-hooks';
import {CommonService} from '../../../service/CommonService';
import {AgentService} from '../../dai-ly/danh-sach/AgentService';
import {RequestService as Service} from './RequestService';
import {useCrupRequestItems} from './RequestItems';
import {CustomerService} from '../danh-sach/CustomerService';

export const ExtensionRequest = ({reloadLazyData}) =>{
  const dataKey = 'requestId';
  const [display, setDisplay] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [taxAuthority, setTaxAuthority] = useState([]);
  const [agents, setAgents] = useState([]);
  const [readOnly, setReadOnly] = useState(false)
  const [productCategory, setProductCategory] = useState([])
  const [productCategoryId, setProductCategoryId] = useState('')
  const [customerPartyTax, setCustomerPartyTax] = useState([]);

  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
    AgentService.getTreeList('N').then(data => {
      const _parents = Service.dataTreeTableToTreeSelect(data.orgTree, item => {
        return `[${item.pseudoId}] - ${item.partyName}`;
      });
      setAgents(_parents);
    });
    CommonService.getProductCategory().then(data => {
      setProductCategory(data?.listData?.map(item => {
        return {value: item.productCategoryId, label: item.categoryName}
      }))
    })
  }, []);

  const searchCustomerPartyTax = event => {
    CustomerService.find(event.query).then(data => {
      setCustomerPartyTax(data?.listData?.map(item => {
        return {...item, value: item.partyId, label: `${item.pseudoId} - ${item.partyName}`}
      }))
    })
  }

  const {renderProducts, items, setItems, itemProductDelIds} = useCrupRequestItems({
    display,
    readOnly,
    productCategoryId
  });
  const productForm = useForm({
    fields: [
      {
        field: 'effectiveDate',
        header: 'Ngày bắt đầu sử dụng',
        className: 'md:col-6',
        InputTextProps: {type: 'date'}
      },
      {
        field: 'productCategoryId',
        header: 'Loại dịch vụ',
        className: 'md:col-6',
        type: 'Dropdown',
        DropdownProps: {options: productCategory}
      },
      {type: 'custom', body: renderProducts()}
    ]
  })
  const {render: renderDialogCrup, create, update, view, form} = useDialogCrup({
    header:'!Gia hạn yêu cầu',
    dataKey,
    width: '100rem',
    fields: [
      {
        field: 'partyId',
        header: 'Mã số thuế khách hàng',
        required: true,
        type:'AutoComplete',
        AutoCompleteProps:{suggestions: customerPartyTax, completeMethod: searchCustomerPartyTax, field: 'label'},
        className: 'md:col-3',
      },
      {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-9'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-3'},
      {
        field: 'stateProvinceGeoId',
        header: 'Tỉnh/Thành phố',
        required: true,
        type: 'Dropdown',
        DropdownProps: {options: provinces, filter: true},
        className: 'md:col-3'
      },
      {
        field: 'countyGeoId',
        header: 'Quận/Huyện',
        required: true,
        type: 'Dropdown',
        DropdownProps: {options: counties, filter: true},
        className: 'md:col-3'
      },
      {
        field: 'taxAuthorityId',
        header: 'Cơ quan thuế',
        type: 'Dropdown',
        DropdownProps: {options: taxAuthority, filter: true},
        className: 'md:col-3'
      },
      {field: 'emailAddress', header: 'Email khách hàng',required: true, className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại', className: 'md:col-3'},
      {field: 'bankName', header: 'Tên ngân hàng', className: 'md:col-3'},
      {field: 'bankAccount', header: 'Số tài khoản', className: 'md:col-3'},
      {field: 'representativeName', header: 'Tên người đại diện', className: 'md:col-3'},
      {field: 'representativeJobTitle', header: 'Chức vụ người đại diện', className: 'md:col-3'},
      {
        field: 'agentPartyName',
        header: 'Đại lý',
        required: true,
        className: 'md:col-6',
        disabled: true
      },
      {
        type: 'custom', body: <Fieldset legend='Gói dịch vụ'>
          {productForm.render()}
        </Fieldset>
      }
    ],
    createItem: async item => {
      return Service.reNew(await inject(item));
    },
    reloadLazyData
  });

  const {partyId} = form.getRawValue()

  useEffect(() => {
    if (partyId?.partyId){
      CustomerService.get(partyId?.partyId).then(data =>{
        data.partyId = {value: data.partyId, label: data.partyTaxId};
        data.agentPartyName = `[${data.agentPseudoId}] - ${data.agentPartyName}`;
        form.setValue(data)
      })
    }
  }, [partyId]);

  const productData = productForm.getValue()
  const inject = async item => {
    const data = Object.assign(item, productData);
    data.itemProducts = items
    if (itemProductDelIds) {
      data.itemProductDelIds = itemProductDelIds
    }
    if (partyId){
      data.partyId = partyId.value;
      data.partyTaxId = partyId.label;
    }
    return data;
  }

  const {stateProvinceGeoId} = form.getValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
      CommonService.getTaxAuthority(stateProvinceGeoId).then(data => {
        setTaxAuthority(data.listData.map(item => {
          return {value: item.taxAuthorityId, label: item.description}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  useEffect(() => {
    if (productData?.productCategoryId) {
      setProductCategoryId(productData.productCategoryId)
      setItems([])
    }
  }, [display, productData?.productCategoryId]);


  const doCreate = () => {
    setDisplay(Date.now());
    setItems([]);
    setCustomerPartyTax([]);
    productForm.setValue({
      effectiveDate: FormatDisplay.date(Date.now(), 'YYYY-MM-DD'),
      productCategoryId: productCategory[0]?.value
    });
    create();
    form.setValue({});
  }

  return {renderReNew: renderDialogCrup, doReNew : doCreate}
}